<template>
  <v-row
    align="center"
    justify="center">
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ $t('crudActions.indexModel', { model: $t('models.accountableTransactions.multiple') }) }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              class="pa-3"
              cols="12"
              md="6">
              <date-picker
                :value.sync="filters.startDate"
                :label="$t('views.transaction.index.fields.startDate')"
                outlined />
              <v-autocomplete
                v-model="filters.entityType"
                :items="entityTypes"
                item-text="name"
                item-value="id"
                auto-select-first
                outlined
                clearable
                :label="$t('views.transaction.index.fields.entityType')" />
              <v-autocomplete
                v-model="filters.entityId"
                :items="entities"
                item-text="name"
                item-value="id"
                auto-select-first
                outlined
                clearable
                :label="$t('views.transaction.index.fields.entity')" />
            </v-col>

            <v-col
              class="pa-3"
              cols="12"
              md="6">
              <date-picker
                :value.sync="filters.endDate"
                :label="$t('views.transaction.index.fields.endDate')"
                outlined />
              <v-autocomplete
                v-model="filters.transactionType"
                :items="transactionTypes"
                item-text="name"
                item-value="id"
                auto-select-first
                outlined
                :label="$t('views.transaction.index.fields.transactionType')"
                multiple />
              <v-autocomplete
                v-model="filters.createdBy"
                :items="users"
                item-text="username"
                item-value="id"
                auto-select-first
                outlined
                clearable
                :label="$t('views.transaction.index.fields.createdBy')" />
            </v-col>
          </v-row>
          <v-row
            class="px-3"
            align="center">
            <v-switch
              v-model="showNotes"
              inset
              :label="$t('views.transaction.index.fields.showNotes')" />
            <v-btn
              class="ml-auto"
              color="success"
              @click="getTransactions">
              {{ $t("actions.filter") }}
            </v-btn>
          </v-row>
          <br>
          <data-table
            class="stripped"
            dense
            :loading="loading"
            :headers="tableColumns"
            :items="accountableTransactions">
            <template
              v-if="!showNotes"
              #item.notes="{ item }">
              <v-tooltip
                v-if="item.notes"
                bottom>
                <template #activator="{ on, attrs }">
                  <font-awesome-icon
                    icon="comment-alt"
                    v-bind="attrs"
                    v-on="on" />
                </template>
                <span>{{ item.notes }}</span>
              </v-tooltip>
            </template>
          </data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchAccountableTransactions, getParams } from "../../api/accountableTransaction.js";
import ContentLoader from "../../mixins/ContentLoader.js";
import { i18n } from "../../i18n";
import DatePicker from "../../components/inputs/DatePicker";
import moment from "moment";
import DataTable from "@/components/DataTable.vue";

const columns = () => ([
	{
		value: "concept",
		text: i18n.t("views.transaction.index.tableColumns.concept"),
	},
	{
		value: "date",
		text: i18n.t("views.transaction.index.tableColumns.date"),
	},
	{
		value: "time",
		text: i18n.t("views.transaction.index.tableColumns.time"),
	},
	{
		value: "created_by",
		text: i18n.t("views.transaction.index.tableColumns.createdBy"),
	},
	{
		value: "source_entity",
		text: i18n.t("views.transaction.index.tableColumns.sourceEntity"),
	},
	{
		value: "destination_entity",
		text: i18n.t("views.transaction.index.tableColumns.destinationEntity"),
	},
	{
		value: "source_opening_balance",
		text: i18n.t("views.transaction.index.tableColumns.sourceEntityOpeningBalance"),
	},
	{
		value: "destination_opening_balance",
		text: i18n.t("views.transaction.index.tableColumns.destinationEntityOpeningBalance"),
	},
	{
		value: "debit",
		text: i18n.t("views.transaction.index.tableColumns.debit"),
	},
	{
		value: "credit",
		text: i18n.t("views.transaction.index.tableColumns.credit"),
	},
	{
		value: "source_closing_balance",
		text: i18n.t("views.transaction.index.tableColumns.sourceEntityClosingBalance"),
	},
	{
		value: "destination_closing_balance",
		text: i18n.t("views.transaction.index.tableColumns.destinationEntityClosingBalance"),
	},
	{
		value: "notes",
		text: i18n.t("views.transaction.index.tableColumns.notes"),
	},
]);
const columnsToHide = ['source_opening_balance', 'destination_opening_balance'];

function data() {
  	return {
		accountableTransactions: [],
		showNotes: false,
		entityTypes: [],
		entities: [],
		transactionTypes: [],
		users: [],
		filters: {
			startDate: moment().format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
			entityType: null,
			entityId: null,
			transactionType: null,
			createdBy: null,
		}
	};
}

export default {
	name: "AccountableTransactionIndex",
	components: {
    DataTable,
		DatePicker,
	},
	mixins: [ContentLoader],
	data,
	computed: {
		tableColumns() {
			return this.showNotes ? columns().filter(c => (!columnsToHide.includes(c.value))) : columns();
		},
	},
	watch: {
		'$store.state.lang' () {
			this.fetchParams();
			this.getTransactions();
		}
	},
	async mounted() {
		this.$startLoading();

		try {
			this.fetchParams();
			this.getTransactions();

			await this.$nextTick();
		} finally {
			this.$finishedLoading();
		}
	},
	methods: {
		fetchParams() {
          return getParams().
            then(response => {
				const { entityTypes, transactionTypes, entities, users } = response.data;
				this.entityTypes = entityTypes;
				this.transactionTypes = transactionTypes;
				this.entities = entities;
				this.users = users;
            });
        },
		getTransactions() {
          return fetchAccountableTransactions(this.filters).
            then(response => {
				const { accountableTransactions, transactionBalances } = response.data;
				this.accountableTransactions = accountableTransactions.map(t => ({
					...transactionBalances[t.id],
					concept: t.transactionType,
					date: moment(t.effectiveDate).format('DD-MM-YYYY'),
					time: moment(t.effectiveDate).format('hh:mm A'),
					created_by: t.createdBy,
					source_entity: t.sourceAccountableEntity.name,
					destination_entity: t.destinationAccountableEntity?.name,
					debit: t.debit,
					credit: t.credit,
					notes: t.notes
				}));
            });
		}
	},
};
</script>
